<template>
    <div class="wrapper">
        <div class="position-relative w-100 vh-75 mb-5">
            <div class="img-fixed h-100 w-100">
                <img :src="ImgPortada" alt="Encuentra una mesa de regalo" class="img-background h-100 w-100">
            </div>
            <div class="position-absolute top-0 bottom-0 start-0 end-0">
                <div class="d-flex justify-content-center align-items-center text-white h-100">
                    <div class="text-center">
                        <h1 class="playfairDisplay display-2">
                          Contacto
                        </h1>
                    </div>
                </div>
            </div>
        </div>


        <div class="position-relative mb-5">
          <img src="@/assets/img/contacto/fondo.jpg" class="" style="position: absolute; left: 0px; right: 0px; z-index: -1;">
          <div class="form-row justify-content-center mx-2">
            <div class="col-lg-5">
              <div class="position-relative">
                <img :src="ImgRose" alt="" class="img-fluid position-absolute end-0 bottom-0" style="z-index: 1;">
                <img :src="ImgTopRight" alt="" class="img-fluid position-absolute bottom-0" style="z-index: 1; top: -95px !important; right: -192px;">
                <img :src="ImgLeft" alt="" class="img-fluid position-absolute bottom-0" style="z-index: 1; left: -137px;">
                <div class="card">
                  <div class="card-body py-5">
                    <h1 class="playfairDisplay mb-3">¿En qué podemos ayudarte?</h1>
                    <form action="POST" v-on:submit.prevent="contacto" ref="contacto" class="position-relative" style="z-index: 2;">
                      <div class="row">
                        <div class="col-lg-12 mb-4">
                          <input type="text" class="form-control" placeholder="Nombre" max="150" v-model="form.nombre" required>
                        </div>

                        <div class="col-lg-12 mb-4">
                          <input type="mail" class="form-control" placeholder="Correo" max="150" v-model="form.correo" required>
                        </div>

                        <div class="col-lg-12 mb-4">
                          <input type="text" class="form-control" placeholder="Asunto" max="150" v-model="form.asunto" required>
                        </div>

                        <div class="col-lg-12 mb-4">
                          <textarea class="form-control form-control bg-transparent" placeholder="Escribe un mensaje" max="250" v-model="form.descripcion" rows="4" required></textarea>
                        </div>

                        <div class="col-lg-12 mb-4">
                          <button type="sumit" class="btn btn-primary btn-lg" :disabled="loadingContacto">
                            <BtnLoading v-if="loadingContacto" />
                            <span v-else>
                              <font-awesome-icon :icon="faPaperPlane" /> Envíar
                            </span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import ImgPortada from '@/assets/img/contacto/contacto2.jpg'
import ImgBackground from '@/assets/img/contacto/background.png'
import ImgRose from '@/assets/img/contacto/rosa.png'
import ImgTopRight from '@/assets/img/contacto/top-right.png'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import BtnLoading from '@/components/BtnLoading.vue'

export default {
  components: {
    FontAwesomeIcon,
    BtnLoading
  },
  data(){
      return {
          ImgPortada,
          ImgBackground,
          ImgRose,
          ImgTopRight,
          faPaperPlane,
          form: {
            nombre: '',
            correo: '',
            asunto: '',
            descripcion: ''
          },
          loadingContacto: false
      }
  },
  methods: {
    contacto(){
      this.$toast.info(`Información envíada, pronto nos pondremos en contacto contigo...`)
    }
  }
}
</script>